import restaurant from "./images/oscar1.jpg";
import biserica from "./images/manastirea.jpg";
import img_card from "./images/baby1.jpg";
import imgOmSuflet from "./images/5.jpg";
import imgOmSufletmb from "./images/5m.jpg";
import imgheader from "./images/2.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/9.jpg";
import imgheadermiinimb from "./images/9m.jpg";
import imgconfirmare from "./images/11.jpg";



const data = {
    introData: [{
        familia: "fam. Moisei",
        mire: "Malvina",
        mireasa: "Maxim",
        data: "12 August 2022",
        data_confirmare: "29 iulie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "malynushvinaga@gmail.com", 
       tel: "+373 797 54 392",
       phone: "tel:+37379754392",
       viber: "viber://chat?number=%2B37379754392",
       whatsapp: "https://wa.me/+37379754392",
       messenger: "https://www.messenger.com/t/malynushvinaga",
       tel1: "+373 787 78 418",
       phone1: "tel:+37378778418",
       viber1: "viber://chat?number=%2B37378778418",
       whatsapp1: "https://wa.me/+37378778418",
       messenger1: "https://www.messenger.com/t/carolina.talmazan.1",
    }],
    cardData: [
       
        {
            id: 2,
            img: restaurant,
            title: "Restaurantul",
            localul: "Petrecerea Evenimentelor",
            name: "OSCAR",
            data: "12 august 2022, vineri,",
            ora: "ora 18:00",
            adress: "Bd. Ștefan cel Mare și Sfînt 69",
            harta: "https://goo.gl/maps/uodee84iwW3QSW9U7",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10880.60028680486!2d28.8419758!3d47.0176592!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x26ad40800b7d152a!2sOscar%20Restaurant%20%26%20Banquet%20Hall!5e0!3m2!1sro!2s!4v1654095378261!5m2!1sro!2s"
        },
       

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Salutare!",
            title2: "Mă numesc, Amelia!",
            mami: "",
            tati: "Nicolai & Mariana",
            message: "Împreună cu tati și mami, ",
            message1: "avem un anunț important de făcut! ",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "NE CĂSĂTORIM !!!",
            message: "Pentru că cele mai frumoase momente se sărbătoresc alături de cei dragi, vrem să fim înconjurați de cei mai apropiați și scumpi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celor mai semnificative evenimente din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgOmSuflet,
            imgmobil: imgOmSufletmb,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "",
            parintii_nume1: "",
            parintii_nume2: "",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "CU BINECUVÂNTAREA ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Andrei & Ecaterina",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă ați fi alături de noi într-o zi atât de specială!",
            message: "",
        }
    ],

    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],

}

export default data;